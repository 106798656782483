<template>
  <div class="full-height" v-if="event && matchingQuestions">
    <v-container class="full-height" id="smart-matching">
      <v-row class="full-height" no-gutters>
        <v-col class="full-height">
          <div class="content">
            <h1 class="title">{{ $t('smart-matching.title') }}</h1>

            <div class="questions__wrapper">
              <v-carousel
                height="auto"
                :show-arrows="false"
                :hide-delimiters="true"
                light
                v-model="carouselIndex"
              >
                <v-carousel-item
                  class="questions"
                  v-for="(question, index) in matchingQuestions"
                  :key="index"
                >
                  <question
                    class="question"
                    :question="question"
                    :index="index"
                    :isExhibitor="isExhibitor"
                    :isLast="!hasNext"
                    isMultiple
                    @update-answers="updateAnswers"
                  />
                </v-carousel-item>
              </v-carousel>
            </div>

            <div class="secondary-controls">
              <div class="secondary-controls__prev">
                <v-btn v-if="hasPrevious" text @click="carouselIndex--">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>

              <div class="secondary-controls__label">
                {{
                  $t('smart-matching.pagination', {
                    count: carouselIndex + 1,
                    total: matchingQuestions.length,
                  })
                }}
              </div>

              <div class="secondary-controls__next">
                <v-btn v-if="hasNext && isAnswered(currentQuestion)" text @click="carouselIndex++">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>

              <div class="secondary-controls__progress">
                <v-progress-linear :value="progress" color="primary" height="10" />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Question from '@/components/question/Question.vue';
import { TicketType } from '@/models/ticketing/ticket-type.model';
import store from '@/stores';
import QuestionnaireUtils from '@/helpers/questionnaire/questionnaire.helper';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  ANSWER_MATCHING_QUESTION,
  APP_MATCHING_MODULE,
} from '@/stores/umanize-app/actions/matching/app-matching.actions';
import { GET_MATCHING_QUESTIONS } from '@/stores/agnostic/actions/matching/matching.actions';

export default {
  name: 'SmartMatching',
  components: {
    Question,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch(`${APP_MATCHING_MODULE}/${GET_MATCHING_QUESTIONS}`, to.params.eventId);
    const matchingQuestions = store.getters[`${APP_MATCHING_MODULE}/matchingQuestions`];

    if (QuestionnaireUtils.hasAnsweredLastQuestion(matchingQuestions)) {
      next({ name: 'SmartMatchingConfirmation', params: { ...to.params } });
    } else {
      next();
    }
  },
  data: () => ({
    carouselIndex: 0,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_MATCHING_MODULE, ['matchingQuestions', 'questionsError']),
    hasPrevious() {
      return this.carouselIndex > 0;
    },
    hasUnansweredQuestions() {
      return this.hasNext || !this.isAnswered(this.currentQuestion);
    },
    hasNext() {
      return this.carouselIndex < this.matchingQuestions.length - 1;
    },
    progress() {
      return ((this.carouselIndex + 1) * 100) / this.matchingQuestions.length;
    },
    isExhibitor() {
      return this.event?.ticket?.type === TicketType.exhibitor;
    },
    currentQuestion() {
      return this.matchingQuestions[this.carouselIndex];
    },
    hasFinished() {
      return QuestionnaireUtils.hasAnsweredLastQuestion(this.matchingQuestions);
    },
  },
  methods: {
    ...mapActions(APP_MATCHING_MODULE, [GET_MATCHING_QUESTIONS, ANSWER_MATCHING_QUESTION]),
    async updateAnswers(answerIds) {
      const { eventId } = this.$route.params;
      const questionId = this.matchingQuestions[this.carouselIndex]?.id;

      await this[ANSWER_MATCHING_QUESTION]({ eventId, questionId, answerIds });

      if (!this.hasUnansweredQuestions) {
        this.$router.push({ name: 'SmartMatchingConfirmation' });
      }

      if (!this.questionsError && this.hasNext) {
        this.carouselIndex += 1;
      }
    },
    isAnswered(currentQuestion) {
      return (
        !!this.matchingQuestions.find((question) => question.id === currentQuestion?.id)
          ?.currentAnswerIds?.length > 0
      );
    },
    setCurrentCarouselIndex() {
      this.carouselIndex = QuestionnaireUtils.getCurrentQuestionIndex(this.matchingQuestions);
    },
  },
  async mounted() {
    this.setCurrentCarouselIndex();
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

#smart-matching {
  height: 100%;
}

.full-height {
  height: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  flex: 1;
}

.questions {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 10;

    height: 18rem;
  }
}

.question {
  width: 100%;
}

.controls {
  display: flex;
  justify-content: center;
}

.secondary-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;

  margin: auto;

  &__prev {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__label {
    display: flex;
    justify-content: center;
    flex: 3;
  }

  &__next {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  &__progress {
    padding: 1rem;
    width: 100%;
  }
}

@include breakpoint(small) {
  .questions {
    max-width: 40rem;
    margin: auto;
    text-align: justify;
  }

  .secondary-controls {
    width: 20rem;
  }
}
</style>
