<template>
  <div class="matching-confirmation">
    <div class="confirmation">
      <h1>{{ $t('smart-matching.thanks') }}</h1>

      <div class="text">
        <v-btn x-large to="results" color="primary" outlined>
          {{ $t('smart-matching.results') }}
        </v-btn>
      </div>
      <p>
        <v-btn medium @click="resetAnswers()" color="secondary" text>
          {{ $t('smart-matching.edit') }}
        </v-btn>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_MATCHING_MODULE,
  REMOVE_MATCHING_RESULTS,
} from '@/stores/umanize-app/actions/matching/app-matching.actions';

export default {
  name: 'SmartMatchingConfirmation',
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
  },
  methods: {
    ...mapActions(APP_MATCHING_MODULE, [REMOVE_MATCHING_RESULTS]),
    async resetAnswers() {
      await this[REMOVE_MATCHING_RESULTS](this.event.id);
      await this.$router.push({
        name: 'SmartMatching',
        params: this.$router.currentRoute?.params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.matching-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 100%;
}

.confirmation {
  margin: 1rem;
  width: 30rem;
}

.text {
  padding: 1rem;
  font-size: $large-font-size;
}
</style>
