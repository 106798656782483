<template>
  <div id="smart-matching">
    <loading class="mt-8 ml-8" v-if="!event || resultsAreLoading" />

    <v-container v-else :class="{ 'menu-open': displayMenu }">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="title mt-5 mb-2">{{ $t('relations.result.title') }}</h1>
        </v-col>
      </v-row>

      <v-row no-gutters class="align-center">
        <v-col cols="12" lg="6" class="mb-4">
          <div class="filter" v-if="hasResults">
            <v-radio-group
              class="filter__item"
              name="ticket-type"
              row
              :value="resultsFilterTicketType"
              @change="updateTicketTypeFilter"
              hide-details
              data-test-id="filter-type"
            >
              <v-radio
                v-for="ticketType in getTicketTypes()"
                :key="`filter-ticket-type-${ticketType}`"
                :label="$t(`admin.tickets.type.${ticketType}`)"
                :value="ticketType"
              />
            </v-radio-group>
          </div>
        </v-col>

        <v-col cols="12" lg="3" class="mb-4">
          <div class="search">
            <AppSearch class="search__item" @change="onSearchChange" />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex flex-wrap">
          <div class="results">
            <relation
              class="result flex"
              v-for="relation in filteredResults"
              :key="relation.id"
              :relation="relation"
              :isContact="isContact(relation)"
              :show-calendar="getUserHasAppointment(relation.id)"
              :loggedUserId="loggedInUser.id"
              :user-clickable="true"
              @add-connection="addConnection"
              @remove-connection="removeConnection"
              @go-to-profile="openProfile"
              @chat="openChat"
              @booking="booking"
              @click="openProfile"
            />
          </div>

          <p class="body-1 text-center mt-4 results-none" v-if="!hasResults">
            {{ $t('relations.result.noResult') }}
          </p>

          <p class="body-1 text-center mt-4 results-none-filtered" v-else-if="!hasFilteredResults">
            {{ $t('relations.result.noFilteredResult') }}
          </p>
        </v-col>
      </v-row>
    </v-container>

    <user-info
      v-if="displayMenu"
      :user="selectedUser"
      :menu-open="displayMenu"
      :event-id="event.id"
      @change="toggleDisplayMenu"
      @close-menu="closeProfile"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Relation from '@/components/relation/Relation.vue';
import Loading from '@/components/loading/Loading.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import UserInfo from '@/components/user-info/UserInfo.vue';

import { TicketType } from '@/models/ticketing/ticket-type.model';
import { toUserBooking } from '@/navigation';

import {
  ADD_TO_CONTACTS,
  GET_RELATIONS,
  RELATIONS_MODULE,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import {
  APP_MATCHING_MODULE,
  FILTER_MATCHING_RESULTS,
  GET_MATCHING_RESULTS,
} from '@/stores/umanize-app/actions/matching/app-matching.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'SmartMatching',
  data: () => ({
    carouselIndex: 0,
    searchText: '',
    selectedUser: null,
    displayMenu: false,
  }),
  components: {
    Relation,
    Loading,
    AppSearch,
    UserInfo,
  },
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_MATCHING_MODULE, [
      'filteredResults',
      'hasFilteredResults',
      'hasResults',
      'results',
      'resultsAreLoading',
      'resultsFilter',
      'resultsFilterTicketType',
    ]),
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    eventId() {
      return this.$route.params.eventId;
    },
  },
  methods: {
    ...mapActions(APP_MATCHING_MODULE, [GET_MATCHING_RESULTS, FILTER_MATCHING_RESULTS]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
    getTicketTypes() {
      return [TicketType.all, TicketType.visitor, TicketType.exhibitor, TicketType.speaker];
    },
    onSearchChange(search) {
      this.searchText = search;
      this.search();
    },
    toggleDisplayMenu(value) {
      if (this.displayMenu !== value) {
        this.displayMenu = value;
      }
    },
    openProfile(user) {
      this.selectedUser = user;
      this.displayMenu = true;
    },
    closeProfile() {
      this.selectedUser = null;
      this.displayMenu = false;
    },
    isContact(relation) {
      return this.isConnected(relation.id);
    },
    addConnection(relation) {
      this[ADD_TO_CONTACTS](relation);
    },
    removeConnection(relation) {
      this[REMOVE_FROM_CONTACTS](relation);
    },
    openChat(relation) {
      this[OPEN_CHAT]({ userIds: [relation.id] });
    },
    booking(relation) {
      toUserBooking(this.$router)({
        eventId: this.eventId,
        userId: relation.id,
      });
    },
    updateTicketTypeFilter(ticketType) {
      this[FILTER_MATCHING_RESULTS]({
        ...this.resultsFilter,
        ticketType,
      });
    },
    search() {
      this[FILTER_MATCHING_RESULTS]({
        ...this.resultsFilter,
        searchText: this.searchText,
      });
    },
  },
  async mounted() {
    await this[GET_MATCHING_RESULTS](this.eventId);
    await this[CHECK_USERS_HAVE_APPOINTMENT]({
      eventId: this.eventId,
    });
    this[GET_RELATIONS]();
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/core/variables';

.filter {
  &__item {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.search {
  width: 100%;
}

.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
}

.result {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--v-primaryBackground-base);
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 30px 30px 0;
}

@include breakpoint(small) {
  .menu-open {
    padding-left: 40vw;
  }

  .result {
    width: 45%;
  }
}

@include breakpoint(medium) {
  .menu-open {
    padding-left: 10vw;
  }

  .result {
    width: 30%;
  }

  .search {
    width: 100%;
  }
}
</style>
